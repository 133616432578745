exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .green-flag_green-flag-horizontal_lX16x {\r\n    width: 48px;\r\n    height: 48px;\r\n    -webkit-flex-shrink: 0;\r\n        -ms-flex-negative: 0;\r\n            flex-shrink: 0;\r\n    /* padding: 0.375rem;\r\n    border-radius: 0.25rem; */\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    user-drag: none;\r\n    cursor: pointer;\r\n} .green-flag_green-flag_mk1Vo {\r\n    width: 2rem;\r\n    height: 2rem;\r\n    padding: 0.25rem;\r\n    border-radius: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    user-drag: none;\r\n    cursor: pointer;\r\n} .green-flag_green-flag_mk1Vo:hover {\r\n    background-color: hsla(279, 61%, 31%, 0.1);\r\n} .green-flag_green-flag_mk1Vo.green-flag_is-active_17xP9 {\r\n    background-color: hsla(279, 61%, 31%, 0.2);\r\n} .green-flag_unactive_25PWm {\r\n    opacity: 0.5;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"green-flag-horizontal": "green-flag_green-flag-horizontal_lX16x",
	"greenFlagHorizontal": "green-flag_green-flag-horizontal_lX16x",
	"green-flag": "green-flag_green-flag_mk1Vo",
	"greenFlag": "green-flag_green-flag_mk1Vo",
	"is-active": "green-flag_is-active_17xP9",
	"isActive": "green-flag_is-active_17xP9",
	"unactive": "green-flag_unactive_25PWm"
};