exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".checkbox_checkmark-container_2MNbi {\r\n    display: inline-block;\r\n    position: relative;\r\n    -webkit-user-select: none;\r\n    -moz-user-select: none;\r\n    -ms-user-select: none;\r\n    user-select: none;\r\n}\r\n\r\n.checkbox_checkmark-container_2MNbi input {\r\n    position: absolute;\r\n    opacity: 0;\r\n    cursor: pointer;\r\n    height: 20px;\r\n    width: 20px;\r\n    margin: 0;\r\n}\r\n\r\n.checkbox_checkmark_1bFJZ {\r\n    height: 20px;\r\n    width: 20px;\r\n    border: 1.4px solid #232323;\r\n    border-radius: 3px;\r\n    background-color: #ffffff;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: center;\r\n    -webkit-justify-content: center;\r\n        -ms-flex-pack: center;\r\n            justify-content: center;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.checkbox_checkmark_1bFJZ img {\r\n    height: 16px;\r\n}\r\n\r\n.checkbox_checkmark_1bFJZ:after {\r\n    content: \"\";\r\n    position: absolute;\r\n    display: none;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"checkmark-container": "checkbox_checkmark-container_2MNbi",
	"checkmarkContainer": "checkbox_checkmark-container_2MNbi",
	"checkmark": "checkbox_checkmark_1bFJZ"
};