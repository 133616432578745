exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toggle-switch_toggle-switch_25Mgc{\r\n    position: relative;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-transition: 0.5s;\r\n    transition: 0.5s;\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc label {\r\n    width: 40px;\r\n    height: 25px;\r\n    background: #D4B9DE;\r\n    border-radius: 4px;\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc input:checked + label {\r\n    background-color: hsla(279, 61%, 31%, 1);\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc input:checked + label div {\r\n    -webkit-transition: 0.5s;\r\n    transition: 0.5s;\r\n    margin-left: 15px;\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc input:not(:checked) + label div {\r\n    -webkit-transition: 0.5s;\r\n    transition: 0.5s;\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc label div {\r\n    background-color: white;\r\n    position: relative;\r\n    cursor: pointer;\r\n    left: 2px;\r\n    width: 21px;\r\n    height: 21px;\r\n    top: 1.5px;\r\n    border-radius: 3px;\r\n}\r\n\r\n.toggle-switch_toggle-switch_25Mgc input {\r\n    display: none;\r\n    -webkit-appearance: none;\r\n       -moz-appearance: none;\r\n            appearance: none;\r\n}", ""]);

// exports
exports.locals = {
	"toggle-switch": "toggle-switch_toggle-switch_25Mgc",
	"toggleSwitch": "toggle-switch_toggle-switch_25Mgc"
};