exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".variable_variable_3y8dL {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    gap: .25rem;\r\n    color: #010101;\r\n}\r\n\r\n.variable_variable-type_2kuLx {\r\n    color: #6C6C6C;\r\n}\r\n\r\n.variable_variable-value_2_Zmw {\r\n    font-weight: bold;\r\n}", ""]);

// exports
exports.locals = {
	"variable": "variable_variable_3y8dL",
	"variable-type": "variable_variable-type_2kuLx",
	"variableType": "variable_variable-type_2kuLx",
	"variable-value": "variable_variable-value_2_Zmw",
	"variableValue": "variable_variable-value_2_Zmw"
};