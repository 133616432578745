exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .icon-button_container_Vz-T6 {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: column;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    cursor: pointer;\r\n    font-size: 0.75rem;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    color: hsla(279, 61%, 31%, 1);\r\n    border-radius: 0.5rem;\r\n} .icon-button_container_Vz-T6 + .icon-button_container_Vz-T6 {\r\n    margin-top: 1.25rem;\r\n} .icon-button_title_3_47t {\r\n    margin-top: 0.5rem;\r\n    text-align: center;\r\n} .icon-button_disabled_14mfd {\r\n    opacity: 0.5;\r\n    pointer-events: none;\r\n} .icon-button_container_Vz-T6:active {\r\n    background-color: hsla(279, 61%, 31%, 0.1);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"container": "icon-button_container_Vz-T6",
	"title": "icon-button_title_3_47t",
	"disabled": "icon-button_disabled_14mfd"
};