exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .stage-grid_stage-grid_39blw {\r\n    width: 2rem;\r\n    height: 2rem;\r\n    padding: 0.25rem;\r\n    border-radius: 0.25rem;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    cursor: pointer;\r\n} .stage-grid_stage-grid_39blw:hover {\r\n    background-color: hsla(279, 61%, 31%, 0.1);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"stage-grid": "stage-grid_stage-grid_39blw",
	"stageGrid": "stage-grid_stage-grid_39blw"
};