exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".collapsible-view_container_2K5E3 {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: column;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n    overflow: hidden;\r\n}\r\n\r\n.collapsible-view_header_3MO9U {\r\n    height: 2.5rem;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    gap: 0.25rem;\r\n    padding: 0.875rem 0.625rem;\r\n    background: #F7F7F7;\r\n    border-width: 1px 0 1px 0;\r\n    border-style: solid;\r\n    border-color: #D9D9D9;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"container": "collapsible-view_container_2K5E3",
	"header": "collapsible-view_header_3MO9U"
};