exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".controls_controls-container_3ZRI_ {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n}\r\n\r\n.controls_controls-container-horizontal_3ncuG {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    width:40%;\r\n    -webkit-justify-content: space-around;\r\n        -ms-flex-pack: distribute;\r\n            justify-content: space-around;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_3ZRI_",
	"controlsContainer": "controls_controls-container_3ZRI_",
	"controls-container-horizontal": "controls_controls-container-horizontal_3ncuG",
	"controlsContainerHorizontal": "controls_controls-container-horizontal_3ncuG"
};