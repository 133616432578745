exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".controls_controls-container_ZS2qG {\r\n    /* display: flex; */\r\n    margin-right: 24px;\r\n}\r\n\r\n", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_ZS2qG",
	"controlsContainer": "controls_controls-container_ZS2qG"
};