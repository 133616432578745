exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".editor-zoom-buttons_zoom-icons-container_3Bd98  {\r\n    position: absolute;\r\n    bottom: 40px;\r\n    right: 20px;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: column;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n}\r\n\r\n.editor-zoom-buttons_zoom-icons_7TDeV {\r\n    width: 30px;\r\n    height: 30px;\r\n    background: none;\r\n    outline: none;\r\n    border: none;\r\n    padding: 0;\r\n    margin: 5px 0;\r\n    cursor: pointer;\r\n}\r\n\r\n.editor-zoom-buttons_zoom-icons_7TDeV img {\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    -webkit-user-drag: none;\r\n}", ""]);

// exports
exports.locals = {
	"zoom-icons-container": "editor-zoom-buttons_zoom-icons-container_3Bd98",
	"zoomIconsContainer": "editor-zoom-buttons_zoom-icons-container_3Bd98",
	"zoom-icons": "editor-zoom-buttons_zoom-icons_7TDeV",
	"zoomIcons": "editor-zoom-buttons_zoom-icons_7TDeV"
};