exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".call-stack_stack-frames-list_2MOEp {\r\n    padding: 0.75rem;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-orient: vertical;\r\n    -webkit-box-direction: normal;\r\n    -webkit-flex-direction: column;\r\n        -ms-flex-direction: column;\r\n            flex-direction: column;\r\n    gap: .3rem;\r\n    overflow: auto;\r\n}\r\n\r\n.call-stack_stack-frames-list_2MOEp::-webkit-scrollbar {\r\n    -webkit-appearance: none;\r\n    width: 8px;\r\n    height: 8px;\r\n}\r\n\r\n.call-stack_stack-frames-list_2MOEp::-webkit-scrollbar-thumb {\r\n    border-radius: 4px;\r\n    background-color: rgba(0, 0, 0, 0.2);\r\n    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);\r\n}", ""]);

// exports
exports.locals = {
	"stack-frames-list": "call-stack_stack-frames-list_2MOEp",
	"stackFramesList": "call-stack_stack-frames-list_2MOEp"
};