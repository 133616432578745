exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ /* Position the language select over the language icon, and make it transparent */ .language-selector_language-select_1h0tC {\r\n    position: absolute;\r\n    width: 3rem;\r\n    height: 3rem;\r\n    opacity: 0;\r\n    -webkit-user-select: none;\r\n       -moz-user-select: none;\r\n        -ms-user-select: none;\r\n            user-select: none;\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n    font-size: .875rem;\r\n    color: hsla(225, 15%, 40%, 1);\r\n    background: hsla(0, 100%, 100%, 1);\r\n} [dir=\"ltr\"] .language-selector_language-select_1h0tC {\r\n    right: 0;\r\n} [dir=\"rtl\"] .language-selector_language-select_1h0tC {\r\n    left: 0;\r\n} .language-selector_language-select_1h0tC option {\r\n    opacity: 1;\r\n} .language-selector_language-select_1h0tC:focus {\r\n    border: 1px solid white;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"language-select": "language-selector_language-select_1h0tC",
	"languageSelect": "language-selector_language-select_1h0tC"
};