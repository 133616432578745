exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".stack-frame_stack-frame_20kwg {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: justify;\r\n    -webkit-justify-content: space-between;\r\n        -ms-flex-pack: justify;\r\n            justify-content: space-between;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.stack-frame_info_3fq8A {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    gap: .3rem;\r\n}", ""]);

// exports
exports.locals = {
	"stack-frame": "stack-frame_stack-frame_20kwg",
	"stackFrame": "stack-frame_stack-frame_20kwg",
	"info": "stack-frame_info_3fq8A"
};