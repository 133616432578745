exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .tag-button_tag-button_3MxU5 {\r\n    padding: .625rem 1rem;\r\n    background: hsla(279, 61%, 31%, 1);\r\n    border-radius: 1.375rem;\r\n    color: hsla(0, 100%, 100%, 1);\r\n    height: 2.5rem;\r\n} .tag-button_tag-button-icon_3VB4Q {\r\n    max-width: 1rem;\r\n    max-height: 1rem;\r\n} .tag-button_active_1IIH9 {\r\n    background: hsla(344, 100%, 44%, 1);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"tag-button": "tag-button_tag-button_3MxU5",
	"tagButton": "tag-button_tag-button_3MxU5",
	"tag-button-icon": "tag-button_tag-button-icon_3VB4Q",
	"tagButtonIcon": "tag-button_tag-button-icon_3VB4Q",
	"active": "tag-button_active_1IIH9"
};