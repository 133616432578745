exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".debugger-controls_debugger-controls_9311t {\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    gap: 1.5rem;\r\n    height: 2.75rem;\r\n    -webkit-box-pack: justify;\r\n    -webkit-justify-content: space-between;\r\n        -ms-flex-pack: justify;\r\n            justify-content: space-between;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n    padding: 0.625rem 1.25rem;\r\n}", ""]);

// exports
exports.locals = {
	"debugger-controls": "debugger-controls_debugger-controls_9311t",
	"debuggerControls": "debugger-controls_debugger-controls_9311t"
};