exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* */ /* */ /* */ /* */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 10% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\r\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ body {\r\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\r\n} h2 {\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n} p {\r\n    font-size: 1rem;\r\n    line-height: 1.5em;\r\n} .crash-message_crash-wrapper_15SLi {\r\n    background-color: hsla(279, 61%, 31%, 1);\r\n    width: 100%;\r\n    height: 100%;\r\n    display: -webkit-box;\r\n    display: -webkit-flex;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: center;\r\n    -webkit-justify-content: center;\r\n        -ms-flex-pack: center;\r\n            justify-content: center;\r\n    -webkit-box-align: center;\r\n    -webkit-align-items: center;\r\n        -ms-flex-align: center;\r\n            align-items: center;\r\n} .crash-message_body_3OO__ {\r\n    width: 35%;\r\n    color: white;\r\n    text-align: center;\r\n} .crash-message_reloadButton_35TdL {\r\n    border: 1px solid hsla(279, 61%, 31%, 1);\r\n    border-radius: 0.25rem;\r\n    padding: 0.5rem 2rem;\r\n    background: white;\r\n    color: hsla(279, 61%, 31%, 1);\r\n    font-weight: bold;\r\n    font-size: 0.875rem;\r\n    cursor: pointer;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_15SLi",
	"crashWrapper": "crash-message_crash-wrapper_15SLi",
	"body": "crash-message_body_3OO__",
	"reloadButton": "crash-message_reloadButton_35TdL"
};